"use client";
import React, { useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import styles from "./Tabs.module.scss";

export interface TabsProps<T extends string | number>
  extends Omit<React.ComponentProps<"ul">, "onChange"> {
  value?: T;
  defaultValue?: T;
  onChange?: (value: T, event: React.MouseEvent<HTMLLIElement>) => void;
  items: ReadonlyArray<{
    label: string;
    value: T;
    liProps?: React.ComponentProps<"li">;
  }>;
}

export function Tabs<T extends string | number>({
  value: propsValue,
  defaultValue,
  onChange,
  items,
  className,
  ...rest
}: TabsProps<T>) {
  const [innerValue, setInnerValue] = useState<T | undefined>(defaultValue);
  const value = propsValue ?? innerValue;
  const handleChange = useCallback(
    (value: T, e: React.MouseEvent<HTMLLIElement>) => {
      setInnerValue(value);
      onChange?.(value, e);
    },
    [onChange]
  );

  const width = useMemo(() => {
    return `${100 / items.length}%`;
  }, [items.length]);

  return (
    <ul className={clsx(styles.root, className)} {...rest}>
      {items.map((item) => {
        const isSelected = value === item.value;
        return (
          <li
            key={item.value}
            className={clsx(isSelected && "text__yellow text__bold", isSelected && styles.active)}
            onClick={(e) => handleChange(item.value, e)}
            style={{ width }}
            {...item.liProps}
          >
            {item.label}
          </li>
        );
      })}
    </ul>
  );
}
